.remove-border-radius {
  border-radius: 0;
}

.kt-text-align-center {
  text-align: center;
}

.kt-width-25-percent {
  width: 25%;
}

.deleteItemIcon:hover {
  cursor: pointer;
}

.editItemIcon {
  width: 25%;
}

.editItemIcon:hover {
  cursor: pointer;  
}

.tableRowHoverHandPointer:hover {
  cursor: pointer;  
}

.txtColorBlack {
  color: black;
}

.toDoItemBgColor {
  background-color: #fafafa;
}

.toDoItemDoneBgColor {
  background-color: #9ff3a4;
}

.btn-add-new-item:disabled {
  cursor: not-allowed;
}

.actionTD {
  width: 10%
}

.mr5 {
  margin-right: 5px;
}

.mt-1 {
  margin-top: 1em;
}

.logoutText {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  font-family: "Montserrat", Arial, sans-serif;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.logoutText:after{
  content: "";
  position: absolute;
  height: 1px;
  bottom: 7px;
  left: 0;
  right: 0;
  background-color: #000000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.logoutText:hover:after{
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.col-red {
  color:red;
}
.col-black {
  color: black;
}
.text-center {
  text-align: center !important;
}

.makeScreenCenter {
  margin: 0;
  /* background: yellow; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}